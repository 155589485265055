import PricingCard from '../../components/PricingCard';
import { Helmet } from 'react-helmet-async';
import CreditCard from '../../components/CreditCard';
import '../../styles/Dashboard/Pricing.css';

const Pricing = () => {
  const subscriptionPlans = [
    {
      title: "Seeker Basic",
      price: 4.99,
      features: [
        "Access to Graph Lookup API",
        "Integrated Info Lookup (10 SeekCoin/search)",
        "150 SeekCoin per day",
        "24/7 Customer Support"
      ],
      sellixId: "67277447d92f8"
    },
    {
      title: "Seeker Pro",
      price: 7.99,
      features: [
        "Access to Graph Lookup API",
        "Integrated Info Lookup (10 SeekCoin/search)",
        "350 SeekCoin per day",
        "24/7 Customer Support"
      ],
      popular: true,
      sellixId: "67277ed4ee603"
    },
    {
      title: "Seeker Premium",
      price: 11.99,
      features: [
        "All Basic & Pro Features",
        "Integrated Info Lookup (10 SeekCoin/search)",
        "650 SeekCoin per days",
        "Access to Discord Search Bot",
        "Access to all data not supported by graph",
        "24/7 Customer Support"
      ],
      sellixId: "6727a860551ae"
    }
  ];
  

  const creditPacks = [
    {
      title: "Starter Pack",
      credits: 100,
      price: 1.00,
      sellixId: "6727a30b91295",
      description: "Get started with 100 SeekCoin to enhance your experience."
    },
    {
      title: "Growth Pack",
      credits: 300,
      price: 2.50,
      sellixId: "6727a310a74fc",
      description: "Fuel your growth with 300 SeekCoin."
    },
    {
      title: "Power Pack",
      credits: 550,
      price: 4.5,
      sellixId: "6727a31351d09",
      description: "Maximize your potential with 550 SeekCoin."
    }
  ];
  

  return (
    <div className="pricing-container">
      <Helmet>
        <title>Pricing | Seekbase</title>
        <link rel="icon" type="image/png" href="favicon.ico" sizes="16x16" />
      </Helmet>
      <h1>DONT BUY WEBSITE IN BETA !!!!!!!</h1>
  <div className="background-gradient"></div>
    <section className="pricing-section">
      <h2>Subscription Plans</h2>
      <div className="pricing-grid">
        {subscriptionPlans.map((plan, index) => (
          <PricingCard key={index} {...plan} />
        ))}
      </div>
    </section>
    
    <section className="credits-section">
      <h2>SeekCoins Packs</h2>
      <div className="credits-grid">
        {creditPacks.map((pack, index) => (
          <CreditCard key={index} {...pack} />
        ))}
      </div>
    </section>
  </div>
  );
}

export default Pricing;