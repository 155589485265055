import PropTypes from 'prop-types';

const PricingCard = ({ title, price, features, popular, sellixId }) => {
  return (
    <div className={`pricing-card ${popular ? 'featured' : ''}`}>
      {popular && <div className="popular-tag">Most Popular</div>}
      <div className="card-content">
        {popular ? (
          <svg className="icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z" fill="#EBD3F8"/>
          </svg>
        ) : (
          <svg className="icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2Z" fill="#AD49E1"/>
          </svg>
        )}
        <h3>{title}</h3>
        <div className="price">${price}<span>/month</span></div>
        <ul>
          {features.map((feature, index) => (
            <li key={index}>
              <svg className="check" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" fill="#EBD3F8"/>
              </svg>
              {feature}
            </li>
          ))}
        </ul>
        <button className="sellix-button" data-sellix-product={sellixId}>
          Buy
          <svg className="arrow" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8-8-8z" fill="#2E073F"/>
          </svg>
        </button>
      </div>
    </div>
  );
};

PricingCard.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  features: PropTypes.arrayOf(PropTypes.string).isRequired,
  popular: PropTypes.bool,
  sellixId: PropTypes.string.isRequired
};

export default PricingCard;