import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { CiCamera, CiSaveDown1 } from "react-icons/ci";
import { LuCamera, LuLoader2 } from "react-icons/lu";
import supabase  from '../../supabase';
import { toast } from 'react-toastify';
import '../../styles/Dashboard/Profile.css';

export default function Profile() {
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const navigate = useNavigate()
  const [profile, setProfile] = useState({
    username: '',
    avatar_url: null
  });

  useEffect(() => {
    getProfile();
  }, []);

  async function getProfile() {
    try {
      setLoading(true);
      const { data: { user } } = await supabase.auth.getUser();

      if (!user) throw new Error('No user logged in');

      let { data, error } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', user.id)
        .single();

      if (error) throw error;
      if (data) setProfile(data);
    } catch (error) {
      toast.error('Error loading user data!');
    } finally {
      setLoading(false);
    }
  }

  async function updateProfile({ avatar_url }) {
    try {
      setLoading(true);
      const { data: { user } } = await supabase.auth.getUser();
  
      if (!user) {
        toast.error("Please login!");
        navigate("/login");
        return;
      }
  
      const updates = {
        id: user.id,
        username: profile.username,
        avatar_url,
        updated_at: new Date().toISOString(),
      };
  
      const { error } = await supabase
        .from('profiles')
        .update(updates)
        .match({ id: user.id });
  
      if (error) {
        toast.error("Failed to update profile!");
        console.error("Error updating profile:", error);
      } else {
        toast.success('Profile updated successfully!');
        setProfile((prevProfile) => ({ ...prevProfile, avatar_url }));
      }
    } catch (error) {
      toast.error('Error updating profile!');
      console.error("Error in updateProfile:", error);
    } finally {
      setLoading(false);
    }
  }

  async function uploadAvatar(event) {
    try {
      setUploading(true);
  
      const { data: { user }, error: userError } = await supabase.auth.getUser();
      if (userError || !user) {
        toast.error('Authentication required');
        navigate("/login");
        return;
      }
  
      const file = event.target.files[0];
      if (!file) {
        toast.error("No file selected");
        return;
      }
  
      const fileExt = file.name.split('.').pop().toLowerCase();
      if (!['jpg', 'png'].includes(fileExt)) {
        toast.error("Unsupported file type. Please upload a JPG or PNG image.");
        return;
      }
  
      const filePath = `public/${user.id}-${Math.random()}.${fileExt}`;
  
      const { error: uploadError } = await supabase.storage
        .from('profile-picture')
        .upload(filePath, file, {
          cacheControl: '3600',
          upsert: true
        });
  
      if (uploadError) {
        toast.error(`Error uploading avatar: ${uploadError.message}`);
        return;
      }
  
      const { data: publicUrlData, error: publicUrlError } = supabase.storage
        .from('profile-picture')
        .getPublicUrl(filePath);
  
      if (publicUrlError || !publicUrlData?.publicUrl) {
        toast.error('Error generating public URL');
        return;
      }
  
      await updateProfile({ avatar_url: publicUrlData.publicUrl });
      toast.success('Avatar uploaded successfully!');
    } catch (error) {
      console.error("Error in uploadAvatar:", error);
      toast.error(error.message || 'Error uploading avatar!');
    } finally {
      setUploading(false);
    }
  }
  
  

  return (
    <div className="profile-container">
      <Helmet>
        <title>Profile | Seekbase</title>
        <link rel="icon" type="image/png" href="favicon.ico" sizes="16x16" />
      </Helmet>
      <div className="profile-header">
        <h2>Profile Settings</h2>
        <p>Update your personal information and profile picture</p>
      </div>

      <div className="profile-content">
        <div className="avatar-section">
          <div className="avatar-wrapper">
            {profile.avatar_url ? (
              <img
                src={profile.avatar_url}
                alt="Avatar"
                className="avatar-image"
              />
            ) : (
              <div className="avatar-placeholder">
                {profile.username?.charAt(0)?.toUpperCase() || '?'}
              </div>
            )}
            <label className="avatar-upload-button" htmlFor="avatar">
              <CiCamera size={20} />
              <span>Change</span>
              <input
                type="file"
                id="avatar"
                accept="image/*"
                onChange={uploadAvatar}
                disabled={uploading}
              />
            </label>
          </div>
        </div>

        <div className="form-section">
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <input
              id="username"
              type="text"
              value={profile.username || ''}
              onChange={(e) => setProfile({ ...profile, username: e.target.value })}
            />
          </div>

          <div className="form-group">
            <label htmlFor="fullName">Full Name</label>
            <input
              id="fullName"
              type="text"
              value={profile.full_name || ''}
              onChange={(e) => setProfile({ ...profile, full_name: e.target.value })}
            />
          </div>

          <div className="form-group">
            <label htmlFor="website">Website</label>
            <input
              id="website"
              type="url"
              value={profile.website || ''}
              onChange={(e) => setProfile({ ...profile, website: e.target.value })}
            />
          </div>

          <button
            className="save-button"
            onClick={() => updateProfile({ avatar_url: profile.avatar_url })}
            disabled={loading}
          >
            {loading ? (
              <LuLoader2 className="animate-spin" size={20} />
            ) : (
              <>
                <CiSaveDown1 size={20} />
                <span>Save Changes</span>
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
}