import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import { useNavigate, Link } from 'react-router-dom';
import supabase from '../supabase';
import '../styles/FormStyles.css';
import seeklogo from '../assets/logo.png';
import Footer from '../components/Footer';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleForgotPassword = async (e) => {
    e.preventDefault();

    const { error } = await supabase.auth.resetPasswordForEmail(email, { 
      redirectTo: 'http://localhost:3000/reset-password' 
    });

    if (error) {
      toast.error(error.message);
    } else {
      toast.success('Check your email for the OTP to reset your password!');
      navigate('/verify-otp', { state: { email } });
    }
  };

  return (
    <div>
      <Helmet>
        <title>Forgot Password | Seekbase</title>
        <link rel="icon" type="image/png" href="favicon.ico" sizes="16x16" />
      </Helmet>
    <div className='logo-auth'><Link to="/"><img src={seeklogo} alt="SeekBase Logo" /></Link></div>
    <form className="form-container" onSubmit={handleForgotPassword}>
      <h2>Forgot Password</h2>
      <input
        type="email"
        placeholder="Enter your email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
      />
      <button type="submit">Send OTP</button>
    </form>
    <Footer></Footer>
    </div>
  );
}

export default ForgotPassword;
