import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import supabase from '../supabase.js';
import '../styles/Login.css';
import { Helmet } from 'react-helmet-async';
import DOMPurify from 'dompurify';
import axios from 'axios';  
import Footer from '../components/Footer.jsx';
import seeklogo from '../assets/logo.png';


function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    const cleanEmail = DOMPurify.sanitize(email);
    const cleanPassword = DOMPurify.sanitize(password);

    const { error, data } = await supabase.auth.signInWithPassword({
      email: cleanEmail,
      password: cleanPassword,
    });

    if (error) {
      toast.error(error.message || 'Login failed');
    } else {
      
      try {
        let token = ((await supabase.auth.getSession()).data.session.access_token);
        if (token) {
          const response = await axios.get('https://api.seekbase.shop/api/v1/user/me', {
            headers: { Authorization: `Bearer ${token}` },
          });
          setUser(response.data);
          toast.success("Successful account login, redirection to dashboard page")
          navigate("/dashboard")
        }
      } catch (error) {
          console.error("Error fetching user:", error);
      }
    }
  };

  return (
    <div>
      <div className='logo-auth'><Link to="/"><img src={seeklogo} alt="SeekBase Logo" /></Link></div>
      <div className="login">
      <Helmet>
        <title>Login | Seekbase</title>
        <link rel="icon" type="image/png" href="favicon.ico" sizes="16x16" />
      </Helmet>
        <h2>Login</h2>
        <form onSubmit={handleLogin}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="submit">Login</button>
        </form>
        <a href="/forgot-password" className="forgot-password-link">
            Forgot your password?
          </a>
      </div>
      <Footer></Footer>
      </div>
      
  );
}

export default Login;
