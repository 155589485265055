import React from 'react';
import '../styles/Footer.css';
import seeklogo from '../assets/logo.png';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo">
          <img src={seeklogo} alt="Seekbase Logo" />
        </div>
        <div className="footer-text">
          <p>© 2024 Seekbase. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
