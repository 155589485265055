import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import HeaderDashboard from '../../components/HeaderDashboard';
import { Outlet, NavLink, useNavigate } from 'react-router-dom';
import { FaChartLine, FaHistory, FaUser, FaMoneyBillWave } from 'react-icons/fa';
import '../../styles/Dashboard/Dashboard.css';

function Dashboard() {
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();

  const menuItems = [
    { icon: <FaChartLine size={24} />, text: 'Graph', path: '/dashboard/graph' },
    { icon: <FaHistory size={24} />, text: 'History', path: '/dashboard/history' },
    { icon: <FaUser size={24} />, text: 'Profile', path: '/dashboard/profile' },
    { icon: <FaMoneyBillWave size={24} />, text: 'Price', path: '/dashboard/price' },
  ];

  
  return (
    <div>
      <Helmet>
        <title>Dashboard | Seekbase</title>
        <link rel="icon" type="image/png" href="favicon.ico" sizes="16x16" />
      </Helmet>
      <HeaderDashboard />
      <div className="dashboard">
        <aside 
          className={`dashboard-sidebar ${isExpanded ? 'expanded' : ''}`}
          onMouseEnter={() => setIsExpanded(true)}
          onMouseLeave={() => setIsExpanded(false)}
        >
          <div className="sidebar-content">
            {menuItems.map((item, index) => (
              <NavLink
                key={index}
                to={item.path}
                className={({ isActive }) => 
                  `sidebar-item ${isActive ? 'active' : ''}`
                }
              >
                <span className="icon">{item.icon}</span>
                <span className="text">{item.text}</span>
              </NavLink>
            ))}
          </div>
        </aside>
        <main className="dashboard-main">
          <div className="content-wrapper">
            <Outlet />
            
          </div>
        </main>
      </div>
    </div>
  );
}

export default Dashboard;
