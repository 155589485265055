import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { CiMenuBurger, CiSquareRemove, CiLogout, CiUser, CiHome } from 'react-icons/ci';
import { toast } from 'react-toastify';
import seeklogo from '../assets/logo.png';
import axios from 'axios';
import supabase from '../supabase';
import '../styles/Header.css';

const Header = () => {
  const [user, setUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const session = await supabase.auth.getSession();
        const token = session.data.session?.access_token;
        if (token) {
          const response = await axios.get('https://api.seekbase.shop/api/v1/user/me', {
            headers: { Authorization: `Bearer ${token}` },
          });
          setUser(response.data);
        }
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };

    fetchUser();
  }, [navigate]);

  const handleLogout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      
      localStorage.removeItem('access_token');
      setUser(null);
      navigate('/');
      toast.success('Logged out successfully');
    } catch (error) {
      toast.error("Failed to logout: " + error.message);
    }
  };

  return (
    <motion.header
      className="header"
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className="header-container">
        <Link to="/" className="header-logo">
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="logo-wrapper"
          >
            <img src={seeklogo} alt="Logo" className="logo-icon" />
          </motion.div>
        </Link>
  
        <div className="header-nav">
          {!user ? (
            <div className="auth-buttons">
              <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                <Link to="/login" className="auth-button-login">
                  Login
                </Link>
              </motion.div>
              <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
                <Link to="/signup" className="auth-button-signup">
                  Sign Up
                </Link>
              </motion.div>
            </div>
          ) : (
            <div className="user-menu">
              <Link to="/profile" className="user-profile">
                <motion.img
                  src={user.avatar_url || '/default-avatar.png'}
                  alt="Profile"
                  className="user-avatar"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                />
                <div className="user-info">
                  <span className="username">
                    {user?.user_data?.username || 'User'}
                  </span>
                  <span className="seekcoins">
                    {user?.user_data?.credits || 0} Seekcoins
                  </span>
                </div>
              </Link>
  
              <div className="nav-links">
                <Link to="/dashboard" className="nav-link">
                  <CiHome /> Dashboard
                </Link>
                <Link to="/profile" className="nav-link">
                  <CiUser /> Profile
                </Link>
                <button onClick={handleLogout} className="logout-button">
                  <CiLogout /> Logout
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </motion.header>
  );
  
}

export default Header;
