import React, { useEffect, useRef, useState, useCallback } from "react";
import axios from 'axios';  
import { Link, useNavigate } from 'react-router-dom';
import { CiSearch } from "react-icons/ci";
import { Network } from "vis-network/standalone";
import { toast } from 'react-toastify';
import "vis-network/styles/vis-network.css";
import "./styles/GraphComponent.css";
import supabase from '../supabase';
import DiscordLookup from './lookupComponents/DiscordLookup'
import SteamLookup from './lookupComponents/SteamLookup'
import IpLookup from './lookupComponents/IpLookup'
import FivemidLookup from './lookupComponents/FivemidLookup'
import XboxLookup from "./lookupComponents/XboxLookup";
import fivem_logo from "../images/fivem_logo.png";
import discord_logo from "../images/discord_logo.png";
import steam_logo from "../images/steam_logo.png";
import email_logo from "../images/email_logo.png";
import ip_logo from "../images/ip_logo.png";
import xbox_logo from "../images/xbox_logo.png";
import live_logo from "../images/xbox_logo.png";
import seekbase_logo from "../images/seekbase_logo.png";
import wakanime_logo from "../images/wakanime_logo.png";

const iconMap = {
  email: email_logo,
  ip: ip_logo,
  xbox: xbox_logo,
  live: live_logo,
  fivem: fivem_logo,
  steam: steam_logo,
  discord: discord_logo,
  seekbase: seekbase_logo,
  wakanime: wakanime_logo,
};

const MAX_NODES = 200;
const MAX_DEPTH = 15;
const MAX_CHILDREN_PER_NODE = 25;
let node_lookup = {};

const GraphComponent = () => {
  const containerRef = useRef(null);
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [includeFilename, setIncludeFilename] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);
  const [lookupData, setLookupData] = useState(null);
  const [user, setUser] = useState(null);
  const[token, setToken] = useState(null)
  const navigate = useNavigate();
  const resizeObserverLoopErr = (error) => {
    if (error.message === "ResizeObserver loop completed with undelivered notifications.") {
      return;
    }
    console.error(error);
  };
  
  window.addEventListener("error", resizeObserverLoopErr);
  
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const token = ((await supabase.auth.getSession()).data.session.access_token);
        if (token) {
          const response = await axios.get('https://api.seekbase.shop/api/v1/user/me', {
            headers: { Authorization: `Bearer ${token}` },
            
          });
          setToken(token)
          setUser(response.data);
        }
      } catch (error) {
          console.error("Error fetching user:", error);
      }
    };

    fetchUser();
  }, [navigate]);

  let infosPool = new Set();
  let currentId = 1;

  
  const createNode = useCallback((id, label, title, iconUrl, color = {}) => ({
    id,
    label,
    title,
    shape: "image",
    image: iconUrl,
    font: { color: "#fff" },
    color: {
      background: color.background || "#2E073F",
      border: color.border || "#7A1CAC",
    },
  }), []);

  const initializeNetwork = useCallback(() => {
    if (containerRef.current) {
      const options = {
        nodes: {
          shape: "box",
          color: {
            background: "#2E073F",
            border: "#7A1CAC",
          },
          font: { color: "#EBD3F8" },
          labelHighlightBold: true,
        },
        edges: { color: "#7A1CAC", width: 2 },
        physics: {
          stabilization: true,
          barnesHut: {
            gravitationalConstant: -2000,
            centralGravity: 0.3,
            springLength: 200,
            springConstant: 0.04,
          },
        },
        interaction: { hover: true, tooltipDelay: 300 },
      };

      const network = new Network(containerRef.current, { nodes, edges }, options);

      network.on("click", (params) => {
        const nodeId = params.nodes[0];
        if (nodeId) {
          const nodeData = nodes.find((node) => node.id === nodeId);
          const nodeLookupData = node_lookup[nodeId]; 
          setSelectedNode(nodeData || null);
          setLookupData(nodeLookupData || null);
        } else {
          setSelectedNode(null);
          setLookupData(null);
        }
      });

      return network;
    }
  }, [nodes, edges]);

  useEffect(() => {
    const network = initializeNetwork();
    return () => {
      network && network.destroy();
    };
  }, [nodes, edges, initializeNetwork]);

  const handleSearch = async () => {
    if (user?.user_data?.credits < 10) {
      toast.error("You don't have enough credit");
      return;
    }

    if (!searchQuery) {
      toast.error("Please enter a search query.");
      return;
    }

    try {
      setNodes([]);
      setEdges([]);
      infosPool = new Set();
      currentId = 1;

      const rootNode = createNode(0, `${searchQuery}`, "By SeekBase", iconMap.seekbase);
      setNodes([rootNode]);
      node_lookup[0] = { lookupData: searchQuery };

      const queryParams = new URLSearchParams({
        search_string: searchQuery,
        filename: includeFilename,
      });

      const response = await fetch(`https://api.seekbase.shop/api/v1/search?${queryParams.toString()}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        }
      });
      

      if (response.ok) {
        const data = await response.json();
    
        if (Object.values(data).every(
          (value) => value === null || value === undefined || value === "" || 
          (typeof value === "object" && Object.keys(value).length === 0)
        )) {
          toast.error(`No result for: ${searchQuery}`);
          return;
        }
    
        await fetchAndProcess(searchQuery, 0, 0);
        const response_ = await axios.post(
          'https://api.seekbase.shop/api/v1/user/deduct-credits',
          {},
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
      } else {
        const errorDetails = await response.text();
    
        switch (response.status) {
          case 401:
            toast.error("Unauthorized access. Please log in.");
            break;
          case 403:
            toast.error("Forbidden: You do not have permission to access this resource.");
            break;
          case 404:
            toast.error("Not found: The requested resource could not be found.");
            break;
          case 500:
            toast.error("Internal Server Error: Please try again later.");
            break;
          default:
            toast.error(`Error ${response.status}: ${errorDetails}`);
        }
      }
    } catch (error) {
      toast.error("An error occurred: " + error);
    }
  };

  const countKeys = (data) => {
    return Object.keys(data)
        .filter(key => key !== 'raw')
        .reduce((total, key) => total + Object.keys(data[key]).length, 0);
  };

  const shouldAddNode = (parentNode, label, data) => {
    if (parentNode && parentNode.label === "FiveM ID" && label === "Email") {
      return false;
    }

    const totalChildrenCount = countKeys(data)

    if (totalChildrenCount > MAX_CHILDREN_PER_NODE) {
      return false;
    }

    const childCount = edges.filter(edge => edge.from === parentNode.id).length;
    return childCount < MAX_CHILDREN_PER_NODE;
  };

  const fetchAndProcess = async (query, parentId, depth) => {
    if (currentId > MAX_NODES || depth > MAX_DEPTH) {
      return;
    }

    const queryParams = new URLSearchParams({
      search_string: query,
      filename: includeFilename,
    });

    const response = await fetch(`https://api.seekbase.shop/api/v1/search?${queryParams.toString()}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      }
    });
    

    if (response.ok) {
      const data = await response.json();

      if (Object.values(data).every(value => 
        value === null || value === undefined || value === '' || 
        (typeof value === 'object' && Object.keys(value).length === 0)
      )) {
        return;
      }

      const newNodes = [];
      const newEdges = [];

      const processInfo = (info, label, icon) => {
        const parentNode = nodes.find((node) => node.id === parentId);

        if (!infosPool.has(info) && currentId <= MAX_NODES && shouldAddNode(parentNode, label, data)) {
            infosPool.add(info);
            const node = createNode(currentId, label, `${label}: ${info}`, icon);
            newNodes.push(node);
            newEdges.push({ from: parentId, to: currentId });  
            currentId++;
            fetchAndProcess(info, currentId - 1, depth + 1);
        }
    };
    
    function processData(data) {
      const processEntries = (entries, type, icon) => {
          if (typeof entries === 'object' && entries !== null && Object.keys(entries).length > 0) {
              Object.keys(entries).forEach((key) => {
                  const nodeInfo = entries[key];
                  node_lookup[currentId] = { lookupData: nodeInfo.lookup || null };
                  processInfo(key, type, icon);
              });
          }
      };

        processEntries(data.emails, "Email", iconMap.email);
        processEntries(data.ips, "IP", iconMap.ip);
        processEntries(data.fivem_ids, "FiveM ID", iconMap.fivem);
        processEntries(data.discord_ids, "Discord ID", iconMap.discord);
        processEntries(data.steam_ids, "Steam ID", iconMap.steam);
        processEntries(data.fivem_licenses, "Licence Fivem", iconMap.fivem);
        processEntries(data.xbl, "Xbox ID", iconMap.xbox);
        processEntries(data.live, "Live ID", iconMap.live);
        processEntries(data.wakanime, "Wakanime", iconMap.wakanime);
    }
    processData(data);
    

      setNodes((prevNodes) => [...prevNodes, ...newNodes]);
      setEdges((prevEdges) => [...prevEdges, ...newEdges]);
    } else {
      if (response.status === 500) {
        toast.error("Failed to retrieve data. Please try again later.");
      } else {
        toast.error("Failed to retrieve data."+response.text());
      }
    }
  };


  return (
    <div className="container">
      <div className="search-bar">
        <input
          type="text"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder="Enter search query"
        />
        <button onClick={handleSearch}><CiSearch size={24}/> Search</button>
      </div>
  
      <div ref={containerRef} className="graph-container" />
  
      {selectedNode && (
        <div className="details-node">
          <h3 className="detailsNodeTitle">Node Details</h3>
  
          <div className="info-section">
            <h4 className="detailsNodeTitle">Information:</h4>
            <h3><pre>{selectedNode.title}</pre></h3>
          </div>

          {selectedNode.id && node_lookup[selectedNode.id] && (
            <div className="lookup-section">
              <h4 className="detailsNodeTitle">Node Lookup Information:</h4>
              {selectedNode.label === 'IP' && (
                <IpLookup lookupData={node_lookup[selectedNode.id].lookupData} />
              )}
              {selectedNode.label === 'FiveM ID' && (
              
                <FivemidLookup lookupData={node_lookup[selectedNode.id].lookupData} />
              )}
              {selectedNode.label === 'Discord ID' && (
                <DiscordLookup lookupData={node_lookup[selectedNode.id].lookupData} />
              )}
              {selectedNode.label === 'Steam ID' && (
                <SteamLookup steamId={selectedNode.title.replace("Steam ID: ","")}></SteamLookup>
              )}
              {selectedNode.label === 'Xbox ID' && (
                console.log("xbox Lookup Triggered"),
                <XboxLookup xuid={selectedNode.title.replace("Xbox ID: ","")}/>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}


export default GraphComponent;
