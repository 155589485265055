import axios from 'axios';

const API_BASE_URL = "https://api.seekbase.shop/api/v1/admin";

export const fetchAPIKeys = async (token) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api-keys`, {
      headers: {
        Authorization: `Bearer ${token}`, 
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching API keys:', error);
    throw error;
  }
};


export const createAPIKey = async (pseudo, token, expiry_days = 30) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/api-keys/create`,
      { pseudo, expiry_days },
      {
        headers: {
          Authorization: `Bearer ${token}`, 
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error creating API key:', error);
    throw error;
  }
};

export const revokeAPIKey = async (api_key, token) => {
  try {
    const response = await axios.put(
      `${API_BASE_URL}/api-keys/${api_key}/revoke`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error revoking API key:', error);
    throw error;
  }
};

export const deleteAPIKey = async (api_key, token) => {
  try {
    const response = await axios.delete(
      `${API_BASE_URL}/api-keys/${api_key}/delete`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error('Error deleting API key:', error);
    throw error;
  }
};

export const getAPIKeyStatus = async (api_key, token) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/api-keys/${api_key}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error getting API key status:', error);
    throw error;
  }
};
