import React, { useState } from 'react';
import './styles/SteamLookup.css';
import supabase from '../../supabase';

const SteamLookup = ({ steamId }) => {
  const [steamData, setSteamData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchSteamData = async () => {
    setIsLoading(true);
    setError(null);
    const session = await supabase.auth.getSession();
    const token = session.data.session?.access_token;
    try {
      const response = await fetch(`https://api.seekbase.shop/api/v1/search/steam-infos?steam_id=${steamId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data)
        setSteamData(data);
      } else {
        const errorData = await response.json();
        setError(errorData.message || 'Failed to fetch Steam data');
      }
    } catch (err) {
      setError('An error occurred while fetching Steam data');
    } finally {
      setIsLoading(false);
    }
  };

  if (!steamData) {
    return (
      <div className="steam-lookup">
        <button 
          onClick={fetchSteamData} 
          className="fetch-steam-button"
          disabled={isLoading}
        >
          {isLoading ? 'Loading...' : 'Fetch Steam Data'}
        </button>
        {error && <p className="steam-error">{error}</p>}
      </div>
    );
  }

  return (
    <div className="steam-container">
      <div className="steam-card">
        <div className="steam-header">
          <div className="steam-avatar-wrapper">
            <img 
              src={steamData.avatar} 
              alt={`${steamData.persona_name}'s Avatar`} 
              className="steam-avatar"
            />
            <div 
              className="steam-status" 
              style={{ 
                backgroundColor: steamData.status === 1 ? '#66ff8f' : '#ff6b6b'
              }}
            />
          </div>
        </div>

        <div className="steam-content">
          <div className="steam-user-info">
            <h1 className="steam-username">{steamData.persona_name}</h1>
            <span className="steam-status-text">
              {steamData.status === 1 ? 'Online' : 'Offline'}
            </span>
          </div>

          <div className="steam-details">
            {steamData.real_name && (
              <div className="steam-detail-item">
                <i className="fas fa-user"></i>
                <span className="steam-label">Real Name</span>
                <span className="steam-value">{steamData.real_name}</span>
              </div>
            )}
            
            {steamData.country && (
              <div className="steam-detail-item">
                <i className="fas fa-globe"></i>
                <span className="steam-label">Country</span>
                <span className="steam-value">{steamData.country}</span>
              </div>
            )}
            
            {steamData.created_date && (
              <div className="steam-detail-item">
                <i className="fas fa-calendar"></i>
                <span className="steam-label">Member Since</span>
                <span className="steam-value">{steamData.created_date}</span>
              </div>
            )}

            <div className="steam-detail-item">
              <i className="fas fa-external-link-alt"></i>
              <span className="steam-label">Profile URL</span>
              <a 
                href={steamData.profile_url} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="steam-value steam-link"
              >
                Visit Profile
              </a>
            </div>
          </div>

          {steamData.games && steamData.games.length > 0 && (
            <div className="steam-section">
              <h3 className="steam-section-title">
                <i className="fas fa-gamepad"></i>
                Recent Games
              </h3>
              <div className="steam-games-grid">
                {steamData.games.map((game, index) => (
                  <div key={index} className="steam-game-card">
                    <div className="steam-game-banner">
                      <img 
                        src={game.banner_url || game.library_image_url} 
                        alt={game.game_name} 
                        className="steam-game-img"
                      />
                    </div>
                    <pre>{game.name}</pre>
                    <div className="steam-game-info">
                      <span className="steam-game-name">{game.name}</span>
                      {game.playtime_hours && (
                        <span className="steam-game-hours">
                          {game.playtime_hours} hrs past 2 weeks
                        </span>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {steamData.friends && steamData.friends.length > 0 && (
            <div className="steam-section">
              <h3 className="steam-section-title">
                <i className="fas fa-users"></i>
                Friends
              </h3>
              <div className="steam-friends-grid">
                {steamData.friends.map((friend, index) => (
                  <div key={index} className="steam-friend-card">
                    <img 
                      src={friend.avatar || `http://media.steampowered.com/steamcommunity/public/images/avatars/${friend.id}.jpg`}
                      alt={friend.name} 
                      className="steam-friend-avatar"
                    />
                    <div className="steam-friend-info">
                      <span className="steam-friend-name">{friend.name}</span>
                      <span className="steam-friend-status" style={{
                        color: friend.status === 'Online' ? '#66ff8f' : '#ff6b6b'
                      }}>
                        {friend.status}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className="steam-footer">
          <span>Powered by Steam API and SeekBase</span>
        </div>
      </div>
    </div>
  );
};

export default SteamLookup;