import React, { useState, useEffect } from 'react';
import { Activity, Key, Lock, Plus, RefreshCw, Search, Trash2, AlertTriangle } from 'lucide-react';
import { fetchAPIKeys, createAPIKey, revokeAPIKey, deleteAPIKey, getAPIKeyStatus } from '../api';
import '../styles/AdminPanel.css';
import supabase from '../supabase';


const AdminPanel = () => {
  const [apiKeys, setApiKeys] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedKey, setSelectedKey] = useState(null);
  const [logs, setLogs] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [newKeyData, setNewKeyData] = useState({ pseudo: '', expiry_days: 30 });

  const token = supabase.auth.getSession()

  useEffect(() => {
    loadAPIKeys();
  }, []);

  const loadAPIKeys = async () => {
    try {
      setLoading(true);
      const keys = await fetchAPIKeys(token);
      setApiKeys(keys);
    } catch (error) {
      console.error('Failed to load API keys:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCreateKey = async () => {
    try {
      const result = await createAPIKey(newKeyData.pseudo, token, newKeyData.expiry_days);
      setApiKeys([...apiKeys, result]);
      setShowCreateModal(false);
      setNewKeyData({ pseudo: '', expiry_days: 30 });
    } catch (error) {
      console.error('Failed to create API key:', error);
    }
  };

  const handleRevokeKey = async (keyId) => {
    try {
      await revokeAPIKey(keyId, token);
      loadAPIKeys();
    } catch (error) {
      console.error('Failed to revoke API key:', error);
    }
  };

  const handleDeleteKey = async (keyId) => {
    if (window.confirm('Are you sure you want to delete this API key?')) {
      try {
        await deleteAPIKey(keyId, token);
        loadAPIKeys();
      } catch (error) {
        console.error('Failed to delete API key:', error);
      }
    }
  };

  const filteredKeys = apiKeys.filter(key => 
    key.pseudo?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="admin-panel">

      <div className="search-bar">
        <Search size={20} />
        <input
          type="text"
          placeholder="Search API keys..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button onClick={loadAPIKeys}>
          <RefreshCw size={20} />
        </button>
        <button className="create-btn" onClick={() => setShowCreateModal(true)}>
          <Plus size={20} /> Create New Key
        </button>
      </div>

      <div className="content">
        <div className="keys-list">
          {loading ? (
            <div className="loading">Loading API keys...</div>
          ) : (
            filteredKeys.map(key => (
              <div 
                key={key.id} 
                className={`key-card ${selectedKey?.id === key.id ? 'selected' : ''}`}
                onClick={() => setSelectedKey(key)}
              >
                <div className="key-info">
                  <h3>{key.pseudo}</h3>
                  <p className="key-details">
                    <span className={`status ${key.is_active ? 'active' : 'inactive'}`}>
                      {key.is_active ? 'Active' : 'Inactive'}
                    </span>
                    <span className="requests">
                      <Activity size={16} /> {key.requests} requests
                    </span>
                  </p>
                </div>
                <div className="key-actions">
                  <button 
                    className="revoke-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRevokeKey(key.id);
                    }}
                  >
                    <Lock size={16} />
                  </button>
                  <button 
                    className="delete-btn"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteKey(key.id);
                    }}
                  >
                    <Trash2 size={16} />
                  </button>
                </div>
              </div>
            ))
          )}
        </div>

        {selectedKey && (
          <div className="key-details-panel">
            <h2>Key Details</h2>
            <div className="details-content">
              <div className="detail-item">
                <label>API Key ID:</label>
                <p>{selectedKey.id}</p>
              </div>
              <div className="detail-item">
                <label>Created:</label>
                <p>{new Date(selectedKey.created_at).toLocaleString()}</p>
              </div>
              <div className="detail-item">
                <label>Expires:</label>
                <p>{selectedKey.expires_at ? new Date(selectedKey.expires_at).toLocaleString() : 'Never'}</p>
              </div>
              <div className="detail-item">
                <label>Last Used:</label>
                <p>{selectedKey.last_used_at ? new Date(selectedKey.last_used_at).toLocaleString() : 'Never'}</p>
              </div>
            </div>

            <div className="logs-section">
              <h3>Recent Activity</h3>
              <div className="logs-list">
                {logs.map(log => (
                  <div key={log.id} className="log-item">
                    <span className={`status-code status-${Math.floor(log.status_code / 100)}xx`}>
                      {log.status_code}
                    </span>
                    <span className="endpoint">{log.endpoint}</span>
                    <span className="timestamp">{new Date(log.timestamp).toLocaleString()}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>

      {showCreateModal && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Create New API Key</h2>
            <div className="modal-content">
              <div className="input-group">
                <label>Pseudo</label>
                <input
                  type="text"
                  value={newKeyData.pseudo}
                  onChange={(e) => setNewKeyData({...newKeyData, pseudo: e.target.value})}
                  placeholder="Enter a name for this key"
                />
              </div>
              <div className="input-group">
                <label>Expiry (days)</label>
                <input
                  type="number"
                  value={newKeyData.expiry_days}
                  onChange={(e) => setNewKeyData({...newKeyData, expiry_days: parseInt(e.target.value)})}
                  min="1"
                  max="365"
                />
              </div>
              <div className="modal-actions">
                <button className="cancel-btn" onClick={() => setShowCreateModal(false)}>Cancel</button>
                <button className="create-btn" onClick={handleCreateKey}>Create Key</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminPanel;