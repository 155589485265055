import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import supabase from '../supabase';
import '../styles/FormStyles.css';
import Footer from '../components/Footer';
import seeklogo from '../assets/logo.png';

function VerifyOtp() {
  const [otp, setOtp] = useState(Array(6).fill(''));
  const location = useLocation();
  const navigate = useNavigate();
  const email = location.state?.email; // Récupération de l'email depuis l'état de navigation

  const handleOtpChange = (element, index) => {
    if (!isNaN(element.value)) {
      setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

      if (element.nextSibling) {
        element.nextSibling.focus();
      }
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    const otpCode = otp.join('');

    if (!email) {
      toast.error('Email is missing. Please restart the process.');
      return;
    }

    const { error } = await supabase.auth.verifyOtp({
      email,
      token: otpCode,
      type: 'email',
    });

    if (error) {
      toast.error('Invalid OTP. Please try again.');
    } else {
      toast.success('OTP verified successfully! Redirecting to reset password...');
      navigate('/reset-password');
    }
  };

  return (
    <div>
      <Helmet>
        <title>Otp | Seekbase</title>
        <link rel="icon" type="image/png" href="favicon.ico" sizes="16x16" />
      </Helmet>
    <div className='logo-auth'><Link to="/"><img src={seeklogo} alt="SeekBase Logo" /></Link></div>
    <form className="form-container" onSubmit={handleVerifyOtp}>
      <h2>Verify OTP</h2>
      <div className="otp-inputs">
        {otp.map((data, index) => (
          <input
            key={index}
            type="text"
            maxLength="1"
            value={data}
            onChange={(e) => handleOtpChange(e.target, index)}
            required
          />
        ))}
      </div>
      <button type="submit">Verify OTP</button>
    </form>
    <Footer></Footer>
    </div>
  );
}

export default VerifyOtp;
