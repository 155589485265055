import React from 'react';
import PropTypes from 'prop-types';
import { CreditCard as CreditCardIcon, ArrowRight, Sparkles } from 'lucide-react';
import '../styles/Dashboard/CreditCard.css';

const CreditCard = ({ title, credits, price, sellixId }) => {
  return (
    <div className="credit-card">
      <div className="card-content">
        <div className="card-header">
          <CreditCardIcon className="credit-icon" size={32} />
          <div className="sparkle-container">
            <Sparkles className="sparkle" size={16} />
            <Sparkles className="sparkle" size={20} />
            <Sparkles className="sparkle" size={14} />
          </div>
        </div>
        
        <h3 className="card-title">{title}</h3>
        
        <div className="credits-container">
          <div className="credits-amount">
            <span className="credits-value">{credits.toLocaleString()}</span>
            <span className="credits-label">Credits</span>
          </div>
          <div className="credits-glow" />
        </div>
        
        <div className="price-container">
          <span className="currency">$</span>
          <span className="amount">{price}</span>
        </div>
        
        <button 
          className="purchase-button" 
          data-sellix-product={sellixId}
          onClick={() => {
            // Sellix integration code here
            console.log('Purchase initiated:', sellixId);
          }}
        >
          <span>Purchase Now</span>
          <ArrowRight className="arrow-icon" size={20} />
        </button>
      </div>
    </div>
  );
};

CreditCard.propTypes = {
  title: PropTypes.string.isRequired,
  credits: PropTypes.number.isRequired,
  price: PropTypes.number.isRequired,
  sellixId: PropTypes.string.isRequired
};

export default CreditCard;