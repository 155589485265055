import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaGoogle, FaGithub, FaFacebook } from 'react-icons/fa';
import '../styles/Signup.css';
import { Helmet } from 'react-helmet-async';
import DOMPurify from 'dompurify';
import seeklogo from '../assets/logo.png';
import Footer from '../components/Footer';

function Signup() {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const navigate = useNavigate();

  const handleSignup = async (e) => {
    e.preventDefault();

    if (password.length < 8) {
      toast.error('Password must be at least 8 characters long');
      return;
    }

    if (password !== passwordConfirm) {
      toast.error('Passwords do not match');
      return;
    }

    const cleanEmail = DOMPurify.sanitize(email);
    const cleanUsername = DOMPurify.sanitize(username);
    const cleanPassword = DOMPurify.sanitize(password);

    try {
      const response = await fetch('https://api.seekbase.shop/api/v1/auth/signup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: cleanEmail, password: cleanPassword, username: cleanUsername }),
      });

      const data = await response.json();
      if (response.ok) {
        toast.success('Signup successful');
        navigate('/login');
      } else {
        toast.error(data.detail || 'Error during signup');
      }
    } catch (error) {
      toast.error(`Error during signup: ${error.message}`);
    }
  };

  const handleOAuthSignup = async (provider) => {
    try {
      const response = await fetch(`https://api.seekbase.shop/api/v1/auth/${provider}-auth`, {
        method: "POST",
      });

      if (response.ok) {
        toast.success(`${provider} signup successful!`);
        navigate('/');
      } else {
        const data = await response.json();
        toast.error(`Error during ${provider} signup: ${data.detail}`);
      }
    } catch (err) {
      toast.error(`Error: ${err.message}`);
    }
  };

  return (
    <div>
    <div className='logo-auth'><Link to="/"><img src={seeklogo} alt="SeekBase Logo" /></Link></div>
    <div className="signup">
      <Helmet>
        <title>Signup | Seekbase</title>
        <link rel="icon" type="image/png" href="favicon.ico" sizes="16x16" />
      </Helmet>
      <h2>Signup</h2>
      <form onSubmit={handleSignup}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Confirm Password"
          value={passwordConfirm}
          onChange={(e) => setPasswordConfirm(e.target.value)}
          required
        />
        <button type="submit">Signup</button>
      </form>

      <div className="oauth-buttons">
        <button onClick={() => handleOAuthSignup("google")}>
          <FaGoogle /> Sign up with Google
        </button>
      </div>
    </div>
    <Footer></Footer>
    </div>
  );
}

export default Signup;
