import React, { useEffect } from 'react';
import './styles/IpLookup.css'; // Assurez-vous que ce chemin est correct
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe, faMapMarkerAlt, faCalendarAlt, faAt, faUser, faSatelliteDish, faNetworkWired, faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

const IpLookup = ({ lookupData }) => {
  const isLookupDataAvailable = !!lookupData;

  useEffect(() => {
    if (!isLookupDataAvailable) return; // Sortir si les données ne sont pas disponibles

    const { latitude, longitude } = lookupData;

    const map = L.map('map').setView([latitude, longitude], 13);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(map);

    L.marker([latitude, longitude]).addTo(map).bindPopup(`Position: ${latitude}, ${longitude}`).openPopup();

    return () => {
      map.remove();
    };
  }, [isLookupDataAvailable, lookupData]); // Dépendances : assurez-vous d'inclure lookupData

  return (
    <div className="ip-container">
      <div className="ip-card">
        <div className="ip-header">
          <div className="ip-title-wrapper">
            <i className="fas fa-network-wired"></i>
            <h2>IP Information</h2>
          </div>
          <span className="ip-address">{lookupData?.query}</span>
        </div>

        {isLookupDataAvailable ? (
          <div className="ip-content">
            <div className="ip-info-grid">
              <div className="ip-info-item">
                <i className="fas fa-globe"></i>
                <div className="ip-info-text">
                  <span className="ip-label">Country</span>
                  <span className="ip-value">{lookupData.country}</span>
                </div>
              </div>

              <div className="ip-info-item">
                <i className="fas fa-map-marker-alt"></i>
                <div className="ip-info-text">
                  <span className="ip-label">Region</span>
                  <span className="ip-value">{lookupData.region}</span>
                </div>
              </div>

              <div className="ip-info-item">
                <i className="fas fa-city"></i>
                <div className="ip-info-text">
                  <span className="ip-label">City</span>
                  <span className="ip-value">{lookupData.city}</span>
                </div>
              </div>

              <div className="ip-info-item">
                <i className="fas fa-mail-bulk"></i>
                <div className="ip-info-text">
                  <span className="ip-label">Postal Code</span>
                  <span className="ip-value">{lookupData.zip}</span>
                </div>
              </div>

              <div className="ip-info-item">
                <i className="fas fa-location-arrow"></i>
                <div className="ip-info-text">
                  <span className="ip-label">Coordinates</span>
                  <span className="ip-value">{lookupData.latitude}, {lookupData.longitude}</span>
                </div>
              </div>

              <div className="ip-info-item">
                <i className="fas fa-clock"></i>
                <div className="ip-info-text">
                  <span className="ip-label">Timezone</span>
                  <span className="ip-value">{lookupData.timezone}</span>
                </div>
              </div>

              <div className="ip-info-item">
                <i className="fas fa-server"></i>
                <div className="ip-info-text">
                  <span className="ip-label">ISP</span>
                  <span className="ip-value">{lookupData.isp}</span>
                </div>
              </div>

              <div className="ip-info-item">
                <i className="fas fa-building"></i>
                <div className="ip-info-text">
                  <span className="ip-label">Organization</span>
                  <span className="ip-value">{lookupData.org}</span>
                </div>
              </div>
            </div>

            <div className="ip-additional-info">
              <div className="ip-status-grid">
                <div className="ip-status-item">
                  <i className="fas fa-mobile-alt"></i>
                  <span className="ip-status-label">Mobile</span>
                  <span className={`ip-status-badge ${lookupData.mobile ? 'active' : 'inactive'}`}>
                    {lookupData.mobile ? "Yes" : "No"}
                  </span>
                </div>

                <div className="ip-status-item">
                  <i className="fas fa-satellite-dish"></i>
                  <span className="ip-status-label">Satellite</span>
                  <span className={`ip-status-badge ${lookupData.satellite ? 'active' : 'inactive'}`}>
                    {lookupData.satellite ? "Yes" : "No"}
                  </span>
                </div>

                <div className="ip-status-item">
                  <i className="fas fa-shield-alt"></i>
                  <span className="ip-status-label">Proxy</span>
                  <span className={`ip-status-badge ${lookupData.proxy ? 'active' : 'inactive'}`}>
                    {lookupData.proxy ? "Yes" : "No"}
                  </span>
                </div>

                <div className="ip-status-item">
                  <i className="fas fa-server"></i>
                  <span className="ip-status-label">Hosting</span>
                  <span className={`ip-status-badge ${lookupData.hosting ? 'active' : 'inactive'}`}>
                    {lookupData.hosting ? "Yes" : "No"}
                  </span>
                </div>
              </div>
            </div>

            <div id="map" className="ip-map"></div>
          </div>
        ) : (
          <div className="ip-no-data">
            <i className="fas fa-search"></i>
            <p>No data available</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default IpLookup;
